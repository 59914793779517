.center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

.inputfield{
    height: 40px;
}

.logo{
    height: 40px !important;
}